<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3 navbar-offcanvas">
        <router-link
            to="/jual/lisensi"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </router-link>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Bantuan</span>
        </span>
        <Menu>
          <ul class="navbar-nav w-100 pl-3 pl-md-0">
            <li class="nav-item mt-1 mb-n1 active">
              <router-link
                to="/jual/lisensi"
                class="nav-link bg-yellowish text-main"
                title="Lisensi (Free trial 5 hari lagi)"
                >Lisensi<span class="d-md-none">
                  (Free trial 5 hari lagi)</span
                ></router-link>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <router-link to="/jual/bantuan" class="nav-link" title="Chat"
                >Bantuan</router-link>
            </li>
            <li class="nav-item mt-1 mb-n1 active">
              <router-link to="/jual/notifikasi" class="nav-link" title="Notifikasi">Notifikasi <span class="badge badge-pill badge-secondary align-top">1</span></router-link>
            </li>
          </ul>
        </Menu>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">

        <div class="row d-none">
          <div class="col-12">
            <div class="alert alert-secondary bg-light alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
              <button id="submitButton" class="btn btn-sm btn-light border py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-3 mb-2">
          <div class="col-12">
            <select class="form-control form-control-sm" id="sel1" required>
              <option value="">Pilih Kategori...</option>
              <option value="1">Kategori1</option>
              <option value="2">Kategori2</option>
              <option value="3">Kategori3</option>
            </select>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="input-group input-group-sm mb-3">
          <input type="text" class="form-control" placeholder="Cari...">
          <div class="input-group-append">
            <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
          </div>
        </div>

        <div class="row mt-3 mb-5">
          <div class="col-12">
            <p class="mb-3">Klik pertanyaan untuk menampilkan jawabannya.</p>
            <div id="accordion" class="mb-4">
              <div class="card mb-3">
                <div class="card-header bg-white">
                  <a class="collapsed card-link stretched-link" data-toggle="collapse" href="#collapse1" aria-expanded="true">
                    <div class="font-weight-bold">Nemo enim ipsam voluptatem quia voluptas?</div>
                    <div class="small">Kategori1</div>
                  </a>
                </div>
                <div id="collapse1" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header bg-white">
                  <a class="collapsed card-link stretched-link" data-toggle="collapse" href="#collapse2">
                    <div class="font-weight-bold">Nemo enim ipsam voluptatem?</div>
                    <div class="small">Kategori1</div>
                  </a>
                </div>
                <div id="collapse2" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header bg-white">
                  <a class="collapsed card-link stretched-link" data-toggle="collapse" href="#collapse3">
                    <div class="font-weight-bold">Perspiciatis unde omnis iste natus error?</div>
                    <div class="small">Kategori2</div>
                  </a>
                </div>
                <div id="collapse3" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header bg-white">
                  <a class="collapsed card-link stretched-link" data-toggle="collapse" href="#collapse4">
                    <div class="font-weight-bold">Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi?</div>
                    <div class="small">Kategori2</div>
                  </a>
                </div>
                <div id="collapse4" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header bg-white">
                  <a class="collapsed card-link stretched-link" data-toggle="collapse" href="#collapse5">
                    <div class="font-weight-bold">Sed quia non numquam eius modi tempora?</div>
                    <div class="small">Kategori3</div>
                  </a>
                </div>
                <div id="collapse5" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>
<script>
import Menu from "../../components/menu/Menu.vue";
export default {
  setup() {},
  components: {
    Menu,
  },
};
</script>