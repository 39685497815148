<template>
  <div v-if="state.isMobile">
    <a class="btn px-0 d-block d-md-none" @click="tampil">
      <i class="fa fa-ellipsis-h text-light"></i>
    </a>
    <transition name="fade">
      <div v-if="state.show">
        <div class="back-menu" @click="tampil"></div>
        <div class="menu">
          <div class="navbar-collapse row p-3 pb-5 p-md-0 mt-2 mt-md-0">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <div class="navbar-collapse row p-3 pb-5 p-md-0 mt-2 mt-md-0">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped>
.menu {
  position: fixed;
  top: 47px;
  bottom: 0;
  right: 100%;
  width: 71%;
  overflow-y: auto;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: left, visibility;
  z-index: 1050;
  background-color: #fff;
  left: 0;
  visibility: visible;
  border-right: 1px solid #fff;
}
.back-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
import { reactive } from "vue";
export default {
  setup() {
    const state = reactive({
      isMobile: window.innerWidth <= 760 ? true : false,
      show: false,
    });
    const tampil = () => {
      return (state.show = !state.show);
    };
    return {
      state,
      tampil,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
        //ismobile?
      if (window.innerWidth <= 760) {
        this.state.isMobile = true;
      } else {
        this.state.isMobile = false;
      }
    },
  },
};
</script>